class WalletConfig {
  constructor() {
    this.WalletStats = {};
  }

  mnemonicFromString(bsvMnemonic, mnemonic, bsv, num, setter) {
    const words = bsvMnemonic.fromString(mnemonic);
    this.hdPrivKeyFunc(words, bsv, num, setter);
  }

  hdPrivKeyFunc(words, bsv, num, setter) {
    const hdPrivateKey = bsv.HDPrivateKey.fromSeed(words.toSeed());
    const hdPrivateKeyText = hdPrivateKey.toString();
    this.WalletStats = { hdPrivateKey: hdPrivateKeyText };
    this.hdPublicKeyFunc(hdPrivateKey, bsv, num, setter);
  }

  hdPublicKeyFunc(hdPrivateKey, bsv, num, setter) {
    const hdPublicKey = bsv.HDPublicKey(hdPrivateKey);
    const hdPublicKeyText = hdPublicKey.toString();
    this.WalletStats = { ...this.WalletStats, hdPublicKey: hdPublicKeyText };
    this.privateKeyFunc(hdPrivateKey, bsv, num, setter);
  }

  privateKeyFunc(hdPrivateKey, bsv, num, setter) {
    const privateKey = hdPrivateKey.deriveChild(`m/44'/0'/${num}'`).privateKey;
    const privateKeyText = privateKey.toString();
    this.WalletStats = { ...this.WalletStats, privateKey: privateKeyText };
    this.publicKeyFunc(bsv, privateKey, setter);
  }

  publicKeyFunc(bsv, privateKey, setter) {
    const publicKey = bsv.PublicKey.fromPrivateKey(privateKey);
    const publicKeyText = publicKey.toString();
    this.WalletStats = { ...this.WalletStats, publicKey: publicKeyText };
    this.addressFunc(bsv, publicKey, setter);
  }

  addressFunc(bsv, publicKey, setter) {
    const address = bsv.Address.fromPublicKey(publicKey).toString();
    const addressText = address.toString();
    this.WalletStats = { ...this.WalletStats, wallAddress: addressText };
    setter({ ...this.WalletStats });
  }
}

export default new WalletConfig();
