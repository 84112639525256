import apiConfig from "../config/relysiaApi";

import { AuthContext } from "../AuthContextProvider";
import React, { useContext } from "react";

export const useWallet = () => {
  const { userWallet, setUserWallet } = useContext(AuthContext);
  return { userWallet, setUserWallet };
};

class WalletService {
  constructor() {
    this.WalletDetails = {};
  }

  async getwalletBal(walletid, setter) {
    //wallet balance
    await apiConfig
      .get("/v1/metrics", {
        headers: {
          walletID: `${walletid}`,
        },
      })
      .then((res) => {
        let uxto = res.data;
        let balInBsv =
          res.data &&
          res.data.data &&
          res.data.data.data &&
          res.data.data.data.balance &&
          res.data.data.data.balance / 100000000
            ? res.data.data.data.balance / 100000000
            : 0;
        this.WalletDetails = {
          ...this.WalletDetails,
          Balance: balInBsv,
          Uxto: uxto,
        };
      })
      .catch((err) => {
        console.log("metrics error", err, err.response);
        // if (err?.response?.data?.data?.msg?.includes("you don't have 00000000")) {
        //   createwallet('default', dispatch)
        // }
        if (
          err?.response?.data?.data?.msg?.includes("user utxos does not exists")
        ) {
          this.WalletDetails = { ...this.WalletDetails, Balance: 0 };
        }
      });
  }

  async getwalletDetails(walletid, setter) {
    await apiConfig
      .get("/v1/address", {
        headers: {
          walletID: `${walletid}`,
        },
      })
      .then((res) => {
        this.WalletDetails = {
          ...this.WalletDetails,
          Address: res.data.data.address,
          Paymail: res.data.data.paymail,
        };
      })
      .catch((err) => {
        console.log("address error", err, err.response);
        this.createwallet("default", setter);
      });

    //wallet balance
    await this.getwalletBal(walletid, setter);

    //wallet mnemonic
    await apiConfig
      .get("/v1/mnemonic", {
        headers: {
          walletID: `${walletid}`,
        },
      })
      .then((res) => {
        this.WalletDetails = {
          ...this.WalletDetails,
          Mnemonic: res.data.data.mnemonic,
        };
      })
      .catch((err) => {
        console.log(err);
        // if (err?.response?.data?.data?.msg?.includes("you don't have 00000000")) {
        //   createwallet('default', dispatch)
        // }
      });

    //wallet history
    await apiConfig
      .get("/v1/history", {
        headers: {
          walletID: `${walletid}`,
        },
      })
      .then((res) => {
        if (res.data.data.histories) {
          setter({
            ...this.WalletDetails,
            WalletHistory: res.data.data.histories,
          });
        } else {
          setter({ ...this.WalletDetails, WalletHistory: [] });
        }

        // console.log("getting history",res);
      })
      .catch((err) => {
        console.log(err);
        if (
          err?.response?.data?.data?.msg?.includes("you don't have 00000000")
        ) {
          this.createwallet("default", setter);
        }
      });
  }

  async createwallet(name, setter) {
    console.log("creating wallet");
    await apiConfig
      .get("/v1/createWallet", {
        headers: {
          walletTitle: `${name}`,
        },
      })
      .then((res) => {
        console.log("wallet created");
        this.getwalletDetails(res.data.data.walletID, setter);
      })
      .catch((err) => {
        console.log("wallet error", err);
      });
  }
}

export default new WalletService();
