const classActionTypes = {
    SET_ACTIVE_COURSE: "SET_ACTIVE_COURSE",
    START_CREATE_COURSE: "START_CREATE_COURSE",
    CREATE_COURSE_SUCCESS: "CREATE_COURSE_SUCCESS",
    START_UPDATE_COURSE: "START_UPDATE_COURSE",
    UPDATE_COURSE_SUCCESS: "UPDATE_COURSE_SUCCESS",
    START_FETCH_CLASS: 'START_FETCH_CLASS',
    FETCH_COURSE_SUCCESS: "FETCH_COURSE_SUCCESS",
}

export default classActionTypes