/*   */
import { call,  takeLatest, all } from 'redux-saga/effects'
import classActionTypes from  '../class/class-actiontypes'

//create class sagas
function createClassWorker (action) {
 
}
export function* createClassWatcher () {
    yield takeLatest(classActionTypes.START_CREATE_CLASS, createClassWorker)
}


//update class sagas
function updateClassWorker (action) {
 
}
export function* updateClassWatcher () {
    yield takeLatest(classActionTypes.START_FETCH_CLASS, updateClassWorker)
}
// fetch class sagas
function fetchClassWorker (action) {
 
}
export function* fetchClassWatcher () {
    yield takeLatest(classActionTypes.START_FETCH_CLASS, fetchClassWorker)
}


export function* classSagas () {
    all([
        call(createClassWatcher),
        call(updateClassWatcher),
        call(fetchClassWatcher)
    ])
   
}