/**
 * Entry application component used to compose providers and render Routes.
 *
 * Note: Because of
 */

import React, { useLayoutEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import Routes from "./app/router/Routes";
import { I18nProvider, LayoutSplashScreen, ThemeProvider } from "./_metronic";
import { ThemeProvider as ThemeProviderChime } from "styled-components";
import AuthContextProvider from "./AuthContextProvider";
import { ParallaxProvider } from "react-scroll-parallax";
import {
  MeetingProvider,
  lightTheme,
} from "amazon-chime-sdk-component-library-react";
import { ApolloProvider } from "@apollo/react-hooks";
import apolloClient from "./lib/apollo/apollo-client";

export default function App({ store, persistor, basename }) {
  useLayoutEffect(() => {
    var metaogtitle = document.createElement("meta");
    metaogtitle.property = "og:title";
    metaogtitle.content = "Satolearn";
    document.getElementsByTagName("head")[0].appendChild(metaogtitle);
  }, []);

  return (
    /* Provide Redux store */
    <ThemeProviderChime theme={lightTheme}>
      <MeetingProvider>
        <ApolloProvider client={apolloClient}>
          <Provider store={store} loading={<LayoutSplashScreen />}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor}>
              {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
              <React.Suspense fallback={<LayoutSplashScreen />}>
                {/* Firebase auth context api */}
                <AuthContextProvider>
                  {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                  <BrowserRouter basename={basename}>
                    {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                    <LastLocationProvider>
                      {/* Provide Metronic theme overrides. */}
                      <ThemeProvider>
                        {/* Provide `react-intl` context synchronized with Redux state.  */}
                        <ParallaxProvider>
                          <I18nProvider>
                            {/* Render routes with provided `Layout`. */}
                            <Routes />
                          </I18nProvider>
                        </ParallaxProvider>
                      </ThemeProvider>
                    </LastLocationProvider>
                  </BrowserRouter>
                </AuthContextProvider>
              </React.Suspense>
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </MeetingProvider>
    </ThemeProviderChime>
  );
}
