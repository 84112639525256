import React, { useContext, useState } from "react";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import objectPath from "object-path";
import { withRouter, useLocation } from "react-router-dom";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import "./assets/Base.scss";
import { makeStyles } from "tss-react/mui";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { useEffect } from "react";
import { AuthContext } from "../../AuthContextProvider";
import apiConfig from "../../config/relysiaApi";

const Header = loadable(() => import("./header/Header"));
const HeaderMobile = loadable(() => import("./header/HeaderMobile"));
const QuickPanel = loadable(() =>
  import("../../app/partials/layout/QuickPanel")
);
const KtContent = loadable(() => import("./KtContent"));

const styles1 = [];
const htmlClassService = new HTMLClassService();
const useStyles = makeStyles()((theme) => ({
  leftTabCon: {
    zIndex: 20,
    position: "fixed",
    height: "300px",
    top: "30vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginLeft: 2,
  },
  ficon: {
    color: "#ffffff",
    [theme.breakpoints.down("sm")]: {
      color: "#484C5E",
    },
  },
  iconDivActive: {
    backgroundColor: "#484C5E",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "transparent !important",
    },
  },
  iconActive: {
    [theme.breakpoints.down("sm")]: {
      color: "#0abb87",
    },
  },
  iconDiv: {
    cursor: "pointer",
    width: 50,
    height: "33%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:hover": {
      backgroundColor: "#484C5E",
      [theme.breakpoints.down("sm")]: {
        backgroundColor: "#484c5e3b !important",
      },
    },
  },
}));

const Layout = ({
  children,
  subheaderDisplay,
  selfLayout,
  contentExtended,
  layoutConfig,
  contentContainerClasses,
  contentClasses,
  history,
}) => {
  const [usdBalance, setUsdBalance] = useState(0);
  const { userContextApi, userWallet } = useContext(AuthContext);
  const routeLocation = useLocation();
  htmlClassService.setConfig(layoutConfig);
  contentClasses = htmlClassService.classes.content.join(" ");
  contentContainerClasses = htmlClassService.classes.content_container.join(
    " "
  );
  const [walletSidebar, setWalletSidebar] = useState(false);

  const onWalletHandler = () => {
    setWalletSidebar((prevState) => !prevState);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [routeLocation.pathname]);

  useEffect(() => {
    if (userWallet.Balance > 0) {
      (async () => {
        await apiConfig
          .get("/v1/currencyConversion", {
            headers: {
              satoshis: `${userWallet.Balance}`,
              currency: "USD",
            },
          })
          .then((res) => setUsdBalance(res.data.data.balance));
      })();
    }
  }, [userWallet.Balance]);

  // scroll to top after location changes

  const { classes, cx } = useStyles();
  return selfLayout !== "blank" ? (
    <LayoutInitializer
      styles={styles1}
      menuConfig={MenuConfig}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile htmlClassService={htmlClassService} history={history} />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
            id="kt_wrapper"
          >
            {/* kt-wrapper */}
            {/* <!-- begin:: Header READY --> */}

            <Header
              history={history}
              walletSidebar={walletSidebar}
              usdBalance={usdBalance}
              setter={onWalletHandler}
            />
            {/* <NewHeader /> */}
            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}
            <div
              className="kt-body kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-grid--stretch justify-end"
              id="kt_body"
            >
              <div
                className={`p-0 w-full flex flex-col flex-1`}
                id="kt_content"
                style={{ padding: 0, width: "100%" }}
              >
                {children}
              </div>

              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      {/* <ScrollTop /> */}
      <div
        className={`right-popup-mobile-menu ${walletSidebar ? "active" : ""}`}
      >
        <div className="right-inner">
          <div className="p-3 absolute top-0 right-0">
            <div className="h-10 w-10 rounded-full flex items-center justify-center bg-white">
              <button className="close-button" onClick={onWalletHandler}>
                <i className="ri-close-line text-xl"></i>
              </button>
            </div>
          </div>
          <QuickPanel usdBalance={usdBalance} />
        </div>
      </div>

      {/* //left Tab */}
      {(() => {
        if (
          routeLocation.pathname.includes("overview") ||
          routeLocation.pathname.includes("tutor") ||
          routeLocation.pathname.includes("achievements") ||
          routeLocation.pathname.includes("mentoring") ||
          routeLocation.pathname.includes("my-tutors")
        ) {
          return (
            <div className={classes.leftTabCon}>
              <div
                onClick={() => history.push("/overview")}
                className={cx(
                  classes.iconDiv,
                  routeLocation.pathname.includes("overview")
                    ? classes.iconDivActive
                    : ""
                )}
              >
                <HomeIcon
                  className={cx(
                    classes.ficon,
                    routeLocation.pathname.includes("overview")
                      ? classes.iconActive
                      : ""
                  )}
                />
              </div>
              <div
                onClick={() => history.push("/tutor")}
                className={cx(
                  classes.iconDiv,
                  routeLocation.pathname.includes("tutor") &&
                    !routeLocation.pathname.includes("my-tutor")
                    ? classes.iconDivActive
                    : ""
                )}
              >
                <SchoolIcon
                  className={cx(
                    classes.ficon,
                    routeLocation.pathname.includes("tutor") &&
                      !routeLocation.pathname.includes("my-tutor")
                      ? classes.iconActive
                      : ""
                  )}
                />
              </div>
              <div
                onClick={() => history.push("/achievements")}
                className={cx(
                  classes.iconDiv,
                  routeLocation.pathname.includes("achievements")
                    ? classes.iconDivActive
                    : ""
                )}
              >
                <EmojiEventsIcon
                  className={cx(
                    classes.ficon,
                    routeLocation.pathname.includes("achievements")
                      ? classes.iconActive
                      : ""
                  )}
                />
              </div>
            </div>
          );
        }
      })()}
    </LayoutInitializer>
  ) : (
    <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
      <KtContent>{children}</KtContent>
    </div>
  );
};

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
  layoutConfig,
  selfLayout: objectPath.get(layoutConfig, "self.layout"),
  subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
  fluid: objectPath.get(layoutConfig, "content.width") === "fluid",
  contentExtended: objectPath.get(layoutConfig, "content.extended"),
});

export default withRouter(connect(mapStateToProps)(Layout));
