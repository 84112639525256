import React, { createContext, useState, useEffect } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import apiConfig from './config/relysiaApi';
import WalletService from './services/relysia-queries';
import WalletConfig from './services/wallet';

export const AuthContext = createContext({
  userData: null,
  authToken: null,
  isAnonymous: true,
  checked: false,
  Address: null,
  Paymail: null,
  Balance: null,
  Mnemonic: null,
  Uxto: null,
  WalletHistory: null,
  WalletId: '00000000-0000-0000-0000-000000000000',
  hdPrivateKey: null,
  hdPublicKey: null,
  privateKey: null,
  publicKey: null,
  wallAddress: null,
});
let initialState = true;
const AuthContextProvider = (props) => {
  const [allCourses, setAllCourses] = useState(null);
  const [takenCourses, setTakenCourses] = useState(null);
  const [contextLoading, setContextLoading] = useState(true);
  const [userContextApi, setUserContextApi] = useState({
    userData: null,
    authToken: null,
    isAnonymous: true,
    checked: false,
  });
  const [userWallet, setUserWallet] = useState({
    Address: null,
    Paymail: null,
    Balance: null,
    Mnemonic: null,
    Uxto: null,
    WalletHistory: null,
    WalletId: '00000000-0000-0000-0000-000000000000',
  });
  const [userWalletStats, setUserWalletStats] = useState({
    hdPrivateKey: null,
    hdPublicKey: null,
    privateKey: null,
    publicKey: null,
    wallAddress: null,
  });

  const firebaseGetAuthorizedUser = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken(true);
        setUserContextApi({
          isAnonymous: false,
          userData: {
            uid: user.uid,
            displayName: user.displayName,
            email: user.email,
            photoURL: user.photoURL,
          },
          authToken: token,
          checked: true,
        });
        await getCourses().then(async () => {
          await getTakenCourses(user.uid);
        });
        console.log('course getting');
        setContextLoading(false);
      } else {
        await getCourses();
        setUserContextApi({
          isAnonymous: true,
          userData: null,
          authToken: null,
          checked: false,
        });
        setUserWallet({
          Address: null,
          Paymail: null,
          Balance: 0,
          Mnemonic: null,
          Uxto: null,
          WalletHistory: null,
          WalletId: '00000000-0000-0000-0000-000000000000',
        });
        setUserWalletStats({
          hdPrivateKey: null,
          hdPublicKey: null,
          privateKey: null,
          publicKey: null,
          wallAddress: null,
        });
        setTakenCourses(null);
        setContextLoading(false);
      }
    });
  };

  const firebaseOnIdTokenChange = () => {
    firebase.auth().onIdTokenChanged(async (user) => {
      if (user && initialState) {
        initialState = false;
        console.log('start');
        const token = await user.getIdToken(true);

        apiConfig.defaults.headers.common['authToken'] = token;
        await WalletService.getwalletDetails(
          '00000000-0000-0000-0000-000000000000',
          setUserWallet
        );
        WalletConfig.mnemonicFromString(
          window.bsvMnemonic,
          userWallet.Mnemonic,
          window.bsv,
          1,
          setUserWalletStats
        );
        console.log('finish');
      }
    });
  };

  useEffect(() => {
    /* const unlisten  =*/
    const unsubscribe = firebaseGetAuthorizedUser();
    firebaseOnIdTokenChange();
    return () => unsubscribe();
  }, []);

  const getCourses = async () => {
    await firebase
      .database()
      .ref('/courses')
      // .orderByChild('visibility')
      // .equalTo('Public')
      .once('value')
      .then(function (snapshot) {
        setAllCourses(snapshot.val());
      });
  };

  const getTakenCourses = async (uid) => {
    await firebase
      .database()
      .ref('/KeyStore/' + uid)
      .once('value')
      .then(function (snapshot) {
        setTakenCourses(snapshot.val());
      });
  };

  return (
    <AuthContext.Provider
      value={{
        userContextApi,
        setUserContextApi,
        userWallet,
        setUserWallet,
        userWalletStats,
        setUserWalletStats,
        allCourses,
        takenCourses,
        getCourses,
        getTakenCourses,
        contextLoading,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
