import { ApolloClient, InMemoryCache } from "@apollo/client";

const WORDPRESS_GRAPHQL_ENDPOINT = "https://cms.vaionex.com/satolearn/graphql";

const apolloClient = new ApolloClient({
  uri: WORDPRESS_GRAPHQL_ENDPOINT,
  cache: new InMemoryCache(),
});

export default apolloClient;
