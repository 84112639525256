/*   */
import React, { useContext, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import { SnackbarProvider } from "notistack";
import { updateUserStatus } from "../store/ducks/auth.duck";
import { connect } from "react-redux";
import loadable from "@loadable/component";
import "firebase/auth";
import { AuthContext } from "../../AuthContextProvider";

const MeetingPage = loadable(() => import("../pages/home/Meeting"));
const ErrorsPage = loadable(() => import("../pages/errors/ErrorsPage"));
const HomePage = loadable(() => import("../pages/home/HomePage"));
const LogoutPage = loadable(() => import("../pages/auth/Logout"));

const Routes = withRouter(({ history, isUserLogin, updateUserStatus }) => {
  let user = null;

  const { userContextApi } = useContext(AuthContext);

  useEffect(() => {
    if (!isUserLogin) {
      if (userContextApi.checked) {
        // if user is logged in
        updateUserStatus(true);
      }
    }
  }, [userContextApi]);

  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  const { menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => ({
      menuConfig,
      isAuthorized: user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  return (
    <SnackbarProvider
      maxSnack={6}
      //   anchorOrigin={{
      //     vertical: "top",
      //     horizontal: "left",
      //   }}
    >
      <MeetingPage history={history} />

      <LayoutContextProvider history={history} menuConfig={menuConfig}>
        <Switch>
          <Route path="/error" component={ErrorsPage} />
          <Route path="/logout" component={LogoutPage} />
          <Layout>
            <HomePage history={history} userLastLocation={userLastLocation} />
          </Layout>
        </Switch>
      </LayoutContextProvider>
    </SnackbarProvider>
  );
});

const mapStateToProps = ({ auth: { isUserLogin } }) => ({
  isUserLogin,
});

export default connect(mapStateToProps, { updateUserStatus })(Routes);
