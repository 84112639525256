import classActionTypes from './course-actiontypes'
const INITIAL_STATE = {
    activeCourse: null
}
const classReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case classActionTypes.SET_ACTIVE_COURSE:
            return {
                ...state,
                activeCourse: action.payload
            }
    
        default:
            return state
    }
}

export default classReducer