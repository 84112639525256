import { all, call } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";

import { courseSagas } from '../store/course/course-sagas'
import { classSagas } from '../store/class/class-sagas'

import classReducer from '../store/class/class.reducer'
import courseReducer from '../store/course/course.reducer'


export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  class: classReducer,
  course: courseReducer
});


export function* rootSaga() {
  yield all
  ([
    call(auth.saga),
    call(courseSagas),
    call(classSagas)
  ])
  ;
}
