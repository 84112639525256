/*   */
import { call, put, takeLatest, all } from 'redux-saga/effects'
import courseActionTypes from  './course-actiontypes'


function createCourseWorker (action) {
 
}
export function* createCourseWatcher () {
    yield takeLatest(courseActionTypes.START_CREATE_COURSE, createCourseWorker)
}

function updateCourseWorker (action) {
 
}
export function* updateCourseWatcher () {
    takeLatest(courseActionTypes.START_UPDATE_COURSE, updateCourseWorker)
}

export function* courseSagas () {
    yield all([
        call(createCourseWatcher),
        call(updateCourseWatcher)
    ])
    
}