import classActionTypes from './class-actiontypes'
const INITIAL_STATE = {
    activeClass: null
}
const classReducer = (state=INITIAL_STATE, action) => {
    switch (action.type) {
        case classActionTypes.SET_ACTIVE_CLASS:
            return {
                ...state,
                activeClass: action.payload
            }
    
        default:
            return state
    }
}

export default classReducer